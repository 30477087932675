import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../layouts'
import TrustBoxMiniCarousel from 'components/trustbox-mini-carousel'
import TrustBox from 'components/trustpilot'
import Checkmark from 'components/misc/checkmark'
const {
  logos: { ...logos }
} = images

class DemoPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Plan een telefonische demo in | Online salarisadministratie | Employes</title>
            <meta name="description" content="Vraag een demo aan. Daarna neemt één van onze salarisexperts contact op voor een telefonische uitleg van Employes." />
            <meta itemprop="name" content="Plan een telefonische demo in | Online salarisadministratie | Employes" />
            <meta itemprop="description" content="Vraag een demo aan. Daarna neemt één van onze salarisexperts contact op voor een telefonische uitleg van Employes." />
            <meta itemprop="image" content="/static/meta-img.png" />
            <meta name="robots" content="noindex,follow" />
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
          </Helmet>

          <header className="header padding-m">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-5">
                  <div className="title margin-m-bottom">
                    <h5 className="eyebrow turq no-margin">Gratis en vrijblijvende demo</h5>
                    <h2>Ontdek de meest gebruiksvriendelijke HR en salarissoftware</h2>
                    <p>Ben je er ook aan toe om je salarisadministratie en HR te moderniseren? Plan dan een demo in met ons. Na het invullen van het formulier, kun je een tijdstip in onze agenda kiezen. Daarna ontvang je een bevestiging per email.</p>
                  </div>


                  <div className="form">
                    <form name="demo" method="POST" action="" data-netlify="true" data-netlify-honeypot="bot-field" action="/demo-confirmed/">
                      <input type="hidden" name="form-name" value="demo" />
                      <input type="text" name="name" placeholder="Naam*" className="margin-xs-bottom" autoFocus required/>
                      <input type="text" name="company" placeholder="Bedrijfsnaam*" className="margin-xs-bottom" required/>
                      <input type="email" name="email" placeholder="Emailadres*" className="margin-xs-bottom" required/>
                      <input type="text" name="phone" placeholder="Telefoonnummer" className="margin-xs-bottom"/>
                      <button type="submit" className="btn primary lg">Gratis demo inplannen</button>
                    </form>
                  </div>
                </div>

                <div className="col-7" style={{display: 'flex', alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>
                  <div className="margin-m-bottom">
                    <img src={heroKlantenservice} />
                  </div>
                  <div>
                    <TrustBoxMiniCarousel></TrustBoxMiniCarousel>
                  </div>
                </div>

              </div>
            </div>
            <div className="logo-grid padding-xl-top hide-mobile block">
              <div className="container-sm margin-xs-bottom">
                <div className="grid yg align-middle logos">
                  {Object.keys(logos).map((img, idx) => (
                    <div className="col-3 align-middle no-mar" key={idx}>
                      <div className={`logo ${idx === 1 ? 'petossi' : ''}`}>
                        <img src={logos[img]} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="container-sm">
                <div className="grid center text-center yg align-middle">
                  <p className="hide-mobile">We helpen meer dan 2000 bedrijven en 7500+ werknemers.</p>
                </div>
              </div>
            </div>
          </header>


        </main>
      </Layout>
    )
  }
}

export default DemoPage
